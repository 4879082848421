import {
  HttpClient,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiProvider {
  private apiUrl = environment.apiUrl;

  private apiRoutes = {
    user: this.apiUrl + 'users',
    condominiums: this.apiUrl + 'condominiums',
    staff: this.apiUrl + 'staff',
    apartament: this.apiUrl + 'apartament',
    ocupant: this.apiUrl + 'ocupant',
    invitation: this.apiUrl + 'invitation',
    customer: this.apiUrl + 'customer',
    profile: this.apiUrl + 'profile',
    business: this.apiUrl + 'business',
    azure: this.apiUrl + 'azureAuth'
  };

  constructor(
    public http: HttpClient,
    private platform: Platform,
    public loadingCtrl: LoadingController
  ) {
    this.platform.ready().then(() => {
      console.log('hereasdasdasd');
      const filter = {
        box: 'inbox', // 'inbox' (default), 'sent', 'draft'
        indexFrom: 0, // start from index 0
        maxCount: 10, // count of SMS to return each time
      };
    });
  }
  currentUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    const id = user.uid;
    console.log('user current user', id);
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('GET', this.apiRoutes.user + '/' + id, {});
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getByProfileId(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('GET', this.apiRoutes.profile + '/' + id, {});
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  profilefindById(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.profile + '/user/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getProfile(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.profile + '/user/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  condominiumDeleteApartaments(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/deleteApartaments/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getUserList() {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('GET', this.apiRoutes.user, {});
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getAdminRoles() {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.profile + '/admins',
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createPendingOcupant(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'POST',
        this.apiRoutes.ocupant + '/createPendingOcupant',
        data,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  changeCustomerStatus(id, status) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'PUT',
        this.apiRoutes.ocupant + '/updatestatus/' + id,
        { status },
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getAllCondominiums(userId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/user/' + userId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createUser(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.user, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getInvitation(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.invitation + '/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createCondominium(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.condominiums, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createStaff(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.staff, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createApartament(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.apartament, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createOcupant(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.ocupant, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createInvitation(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.invitation, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getOcupant(apartamentId, userId) {
    // /apartament/:apartamentId/user/:userId
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.ocupant +
          '/apartament/' +
          apartamentId +
          '/user/' +
          userId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  findProfileByCode(code) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.profile + '/code/' + code,
        {}
      );

      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getInvitationByApartament(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.invitation + '/apartament/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getMyCondominiums() {
    const user = JSON.parse(localStorage.getItem('user'));
    const id = user.id;
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/user/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getCustomerById(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.customer + '/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getOcupantList(condominiumId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.ocupant + '/list/' + condominiumId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getCondominiumByCustomerId(customerId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/customer/' + customerId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  updateCustomer(customerId, condominiumId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                    <div class="custom-spinner-container" style="text-align:center">
                        <div class="custom-spinner-box"></div>
                        <p><b>Por favor espere...</b></p>
                    </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'PUT',
        this.apiRoutes.condominiums + '/updateCustomer/' + condominiumId,
        {
          customerId,
        }
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  updateProfile(id, data) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                    <div class="custom-spinner-container" style="text-align:center">
                        <div class="custom-spinner-box"></div>
                        <p><b>Por favor espere...</b></p>
                    </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'PUT',
        this.apiRoutes.profile + '/' + id,
        data
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  showApartament(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.apartament + '/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getAllCustomer() {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('GET', this.apiRoutes.customer, {});
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getCondominiumCode(code) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/code/' + code,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createCustomer(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'POST',
        this.apiRoutes.profile + '/admins',
        data
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  uploadDataCondominium(data, id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'POST',
        this.apiRoutes.condominiums + '/ocupants/upload/' + id,
        data
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getApartaments(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.apartament + '/condominium/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getOcupantByApartament(apartamentId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.ocupant + '/apartament/' + apartamentId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  condominiumStaff(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.staff + '/condominium/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getCondominium(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.condominiums + '/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getStaffByUser(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.staff + '/user/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getApartamentsByUser(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.ocupant + '/user/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getListBusiness() {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('GET', this.apiRoutes.business, {});
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getBusinessById(id) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Cargando condominio...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.business + '/' + id,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  createBusiness(data = {}) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere....</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest('POST', this.apiRoutes.business, data);
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getCustomersByBusinessId(businessId) {
    return new Promise(async (resolve, reject) => {
      const loadingOpen: any = await this.loadingCtrl.create({
        spinner: null,
        message: `
                  <div class="custom-spinner-container" style="text-align:center">
                      <div class="custom-spinner-box"></div>
                      <p><b>Por favor espere...</b></p>
                  </div>`,
      });
      await loadingOpen.present();

      const req = new HttpRequest(
        'GET',
        this.apiRoutes.customer + '/business/' + businessId,
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            loadingOpen.dismiss();
            resolve(event.body);
          }
        },
        (error) => {
          loadingOpen.dismiss();
          reject(error);
        }
      );
    });
  }

  getAzureToken() {
    return new Promise(async (resolve, reject) => {
      const req = new HttpRequest(
        'GET',
        this.apiRoutes.azure + '/getToken',
        {}
      );
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            resolve(event.body);
          }
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getPowerBiReports(token) {
    return new Promise(async (resolve, reject) => {
      const req = new HttpRequest(
        'GET',
        this.apiRoutes.azure + '/getReports/' + token,
        {}
     );
     this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          resolve(event.body)
        }
      },
      (error) => {
        reject(error);
      }
     )
    })
  }
}
