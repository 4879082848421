import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'customer-list',
    loadChildren: () =>
      import('./pages/customer-list/customer-list.module').then(
        (m) => m.CustomerListPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./pages/business/business.module').then(
        (m) => m.BusinessPageModule
      ),
  },
  {
    path: 'condominium-list',
    loadChildren: () =>
      import('./pages/condominium-list/condominium-list.module').then(
        (m) => m.CondominiumListPageModule
      ),
  },
  {
    path: 'condominium/:id',
    loadChildren: () =>
      import('./pages/condominium/condominium.module').then(
        (m) => m.CondominiumPageModule
      ),
  },
  {
    path: 'apartment/:id',
    loadChildren: () =>
      import('./pages/apartment/apartment.module').then(
        (m) => m.ApartmentPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'powerbi',
    loadChildren: () => import('./pages/powerbi/powerbi.module').then( m => m.PowerbiPageModule)
  },
  // {
  //   path: 'register',
  //   loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
