import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'home', url: '/home', icon: 'home-outline' },
    {
      title: 'Creación de Empresa',
      url: 'business',
      icon: 'storefront-outline',
    },
    {
      title: 'Creación de Administradores Estrellas',
      url: 'customer-list',
      icon: 'star-outline',
    },
    {
      title: 'Creación de Grupos',
      url: 'condominium-list',
      icon: 'business-outline',
    },
    {
      title: 'Reportes',
      url: 'powerbi',
      icon: 'business-outline',
    },
    { title: 'Perfil', url: 'profile', icon: 'person-outline' },

    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  // public labels = [];
  constructor(public menu: MenuController) {}

  ngOnInit() {
    this.menu.enable(false);
  }
}
